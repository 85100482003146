import HomePage from 'components/HomePage';
import PageWrapper from 'components/PageWrapper';

import { getHomePage } from 'utils/graphcms/queries/homePage';

export default function LocationGatePage({ page }) {
  return (
    <>
      {page && (
        <PageWrapper page={page}>
          <HomePage page={page} />
        </PageWrapper>
      )}
    </>
  );
}

export async function getStaticProps() {
  const pageData = await getHomePage();
  const homePage = pageData.homePages[0];

  return {
    props: {
      page: {
        ...homePage,
        venues: pageData.venues,
      },
    },
  };
}
